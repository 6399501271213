<template>
  <div>
    <welcome-conference-banner class='py-8' background-color='#FAFAFA'/>
  </div>
</template>

<script>
import WelcomeConferenceBanner from '@/components/welcome/WelcomeConferenceBanner.vue'

export default {
  name: 'AbsoluteCustomProgram',
  components: {
    WelcomeConferenceBanner,
  },
  created () {
    this.$router.push({name: 'Session', query: {session_id: 645}})
  },
}
</script>
